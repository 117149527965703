/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */

:root {
  --sp-none: 0px;
  --sp-tinier: 2px;
  --sp-tiny: 4px;
  --sp-smaller: 8px;
  --sp-small: 12px;
  --sp-default: 16px;
  --sp-big: 24px;
  --sp-bigger: 32px;
  --sp-great: 40px;
  --sp-greater: 48px;
  --sp-huge: 64px;
  --sp-huger: 80px;
  --sp-immense: 96px;
  --sp-gigantic: 128px;
  --sp-enormous: 160px;
}
