/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is set in pixels,
 *    while the `line-height` is calculated to exist unitlessly.
 * 2. Ensure the page always fills the entire height of the viewport.
 * 3. Ensure that our fonts look great, in browsers where we can use anti-aliasing 👍
 * 4. Set the default application background.
 * 5. Opt-in to border-box as the default box-sizing model.
 */

@use 'sass:math';
@use '../tools/tools.colors' as c;
@use '../settings/settings.core' as core;

html {
  font-size: core.$global-font-size; /* [1] */
  line-height: core.$global-line-height; /* [1] */
  height: 100%; /* [2] */
  -webkit-font-smoothing: antialiased; /* [3] */
  -moz-osx-font-smoothing: grayscale; /* [3] */
}

body {
  background-color: #fff; /* [4] */
  min-height: 100%;
}

* {
  box-sizing: border-box; /* [5] */
}

// quilljs global styles
.ql-tooltip {
  display: none;
}
// reset hr, we never want browser default styles
hr {
  border: none;
  border-bottom: 1px solid c.color(snow, darkest);
}
