/**
 * ACTIMO SASS STYLES
 * -----------------------------------------
 * Setup according to the ITCSS Methodology
 * See high level explanation here: https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
 * and implementation example here: https://github.com/inuitcss/inuitcss/blob/develop/example.main.scss
 * Inspiration for colors and spacing implementation see: https://github.com/Shopify/polaris/tree/master/src/styles
 *
 * Code styleguide: https://github.com/chris-pearce/css-guidelines
 */

// SETTINGS
@use 'settings/settings.core' as core;
@use 'settings/settings.typography' as typography;
@use 'settings/settings.colors' as colorsSettings;
@use 'settings/settings.devices' as devicesSettings;
@use 'tools/tools.colors' as c;
// TOOLS
@use 'tools/tools.helpers' as helpers;
@use 'tools/tools.devices' as devices;
@use 'tools/tools.animations' as aoAnim;
@use 'tools/tools.theming' as tools2;

// GENERIC
@use 'generic/generic.normalize';
@use 'generic/generic.reset' as generic2;

// ELEMENTS
@use 'elements/elements.page' as pageElement;

// OBJECTS
@use 'objects/objects.media';

// BRAND
@use 'brand/colors';

// UI
@use 'ui/list';

// COMPONENTS
// All component styles will be handled locally in the component.
// Do not add/import component styles here!

// Utilities
@use 'utilities/utilities.print';
