@use 'sass:map';
@use '../settings/settings.typography' as typog;

/* ========================================================================
//   #TYPOGRAPHY HELPERS
//   ======================================================================== 

// Returns the font stack for a given family.
//
// @param {String} $family - The key for the given family.
// @return {Number} The font stack for the family.
//
// Example usage:
//   .my-class {
//     font-family: typog.font-family(mono);
//   }
//
//  base: $global-font-family--main,
//  mono: $global-font-family--mono,
//  message: $global-font-family--message */

@function font-family($family: base) {
  $fetched-value: map.get(typog.$font-family-data, $family);

  @if $fetched-value != null {
    @return $fetched-value;
  } @else {
    @error 'Font family `#{$family}` not found. Available font families: #{available-names(typog.$font-family-data)}';
  }
}
