/* ========================================================================
//   #HELPER MIXINS & FUNCTIONS
//   ========================================================================

/// Returns the list of available names in a given map.
/// @param {Map} $map - The map of data to list the names from.
/// @param {Number} $map - The level of depth to get names from.
/// @return {String} The list of names in the map. */

@function available-names($map, $level: 1) {
  @if type-of($map) != 'map' {
    @return null;
  }

  $output: '';
  $newline: '\A ';

  @if $level == 1 {
    @each $key, $value in $map {
      $output: $output + '#{$newline}- #{$key} #{available-names($value, $level + 1)}';
    }
  } @else {
    $output: '(';
    $i: 1;

    @each $key, $value in $map {
      $sep: if($i < length($map), ', ', '');
      $output: $output + '#{$key}#{$sep}#{available-names($value, $level + 1)}';
      $i: $i + 1;
    }

    $output: $output + ')';
  }

  @return $output;
}
