/** adjust to smartop safe areas **/

:root {
  --safe-area-top: env(safe-area-inset-top);
  --safe-area-bottom: env(safe-area-inset-bottom);
}

body.status-bar-available {
  // --------------------------- --------------------------- only top padding
  ao-social-wall-nav-header,
  ao-base-header {
    padding-top: var(--safe-area-top);
  }

  .ao-shell__content--safearea {
    padding-top: var(--safe-area-top);
  }

  .lightbox-modal__close {
    padding-top: var(--safe-area-top);
  }

  // --------------------------- --------------------------- only bottom padding
  &:not(.chat-message-focus) .ao-chat-root {
    padding-bottom: var(--safe-area-bottom);
  }

  .contact-directory-wrapper {
    padding-bottom: var(--safe-area-bottom);
  }

  .viewer-directory__ie-wrapper {
    // not verified
    padding-bottom: var(--safe-area-bottom);
  }

  .ao-footer {
    padding-bottom: var(--safe-area-bottom);
  }

  .ao-task-page-guard {
    padding-bottom: var(--safe-area-bottom);
  }

  .ao-context-menu--mobile {
    padding-bottom: var(--safe-area-bottom);
  }

  .ao-details-peer-view-dialog {
    padding-bottom: var(--safe-area-bottom);
  }

  // --------------------------- --------------------------- both top and bottom padding
  .ao-shell__canvas {
    padding-bottom: var(--safe-area-bottom);
  }

  .ao-message-modal-card__overlay {
    padding-top: var(--safe-area-top);
    padding-bottom: var(--safe-area-bottom);
  }

  .sidenav-top-safearea {
    //mat-sidenav-container
    padding-top: var(--safe-area-top);
    padding-bottom: var(--safe-area-bottom);
  }

  .ao-social-wall-sidebar {
    padding-top: var(--safe-area-top);
    padding-bottom: var(--safe-area-bottom);
  }

  // --------------------------- --------------------------- other changes needed

  .ao-contact-avatar-upload__close {
    top: calc(#{10px} + #{var(--safe-area-top)});
  }

  .ao-navbar {
    padding-bottom: max(12px, var(--safe-area-bottom));
    padding-top: max(12px, var(--safe-area-bottom) / 2);
  }

  .pswp__top-bar {
    padding-top: var(--safe-area-top);
    height: calc(var(--safe-area-top) + 44px);
  }

  .pswp__counter {
    top: var(--safe-area-top);
  }

  // chat empty conversations illustration
  .full-size-portal {
    margin-top: var(--safe-area-top);
  }

  .ao-academy-completed-modal__content__close-button {
    padding-top: var(--safe-area-top);
  }

  .viewer-message__training-footer--sticky-navbar {
    bottom: calc(#{52px} + #{var(--safe-area-bottom)});
  }
}
