/* ------------------------------------*\
//    #COLORS
//\*------------------------------------ */

// These variables are the framework default colors;
// Only modify these if you know what you are doing

// Raw color definitions for primary (brand) Hue's

$color-palette-data: (
  ink: (
    lightest: #9da5b8,
    lighter: #79839a,
    light: #606980,
    base: #353a46,
    dark: #24272e,
    dark-opacity-8: rgba(36, 39, 46, 8%),
  ),
  snow: (
    lightest: #f6f8fa,
    lighter: #f9fafc,
    light: #eff2f7,
    base: #e5e9f2,
    dark: #dde2ee,
    darker: #d6dceb,
    darkest: #c8d0e4,
  ),
  blue: (
    lightest: #f5f8ff,
    lighter: #e1eaff,
    light: #7ba4fe,
    base: #4680fe,
    dark: #325ac8,
    darker: #213991,
  ),
  green: (
    lightest: #e7fbf4,
    lighter: #9fecd6,
    light: #d2f9f2,
    base: #14caaa,
    dark: #11997f,
    darker: #008078,
    darkest: #005353,
  ),
  yellow: (
    lightest: #fff5e2,
    lighter: #ffe0ad,
    light: #fff8df,
    base: #ffb64d,
    dark: #bd8233,
    darker: #914f25,
    darkest: #592815,
  ),
  red: (
    lightest: #ffebec,
    lighter: #ffbbc1,
    light: #ffe0e6,
    base: #fa607f,
    dark: #cc506e,
    darker: #c8435f,
    darkest: #912b41,
  ),
  unread: (
    base: rgb(255 53 37 / 93%),
  ),
  warning: (
    lightest: #fff5e2,
    lighter: #ffe0ad,
    light: #ffcb7b,
    base: #ffb64d,
    dark: #c87f38,
    darker: #914f25,
    darkest: #592815,
  ),
  info: (
    light: #a8c7ff,
    lighter: #e4eeff,
    lightest: #f2f7ff,
    dark: #325ac8,
    darker: #213991,
  ),
);
