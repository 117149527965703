@use '../tools/tools.colors' as c;

// Brand colors
.u-color {
  &--brand {
    &-ink {
      color: c.color(ink) !important;
    }

    &-snow {
      color: c.color(snow) !important;
    }

    &-blue {
      color: c.color(blue) !important;
    }

    &-green {
      color: c.color(green) !important;
    }

    &-yellow {
      color: c.color(yellow) !important;
    }

    &-red {
      color: c.color(red) !important;
    }
  }

  &-bg--brand {
    &-ink {
      background-color: c.color(ink) !important;
    }

    &-snow {
      background-color: c.color(snow) !important;
    }

    &-blue {
      background-color: c.color(blue) !important;
    }

    &-green {
      background-color: c.color(green) !important;
    }

    &-yellow {
      background-color: c.color(yellow) !important;
    }

    &-red {
      background-color: c.color(red) !important;
    }
  }
}

.ao-bg--sheet {
  background-color: #e3faf2;
}
.ao-bg--ppt {
  background-color: #fff5e2;
}
.ao-bg--pdf {
  background-color: #ffdcd9;
}
.ao-bg--doc {
  background-color: #e0ebff;
}
.ao-bg--font {
  background-color: c.color(snow, light);
}
.ao-bg--aud {
  background-color: c.color(snow, dark);
}
.ao-bg--other {
  background-color: c.color(snow, light);
}
