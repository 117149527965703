/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */

:root {
  --ao-font-size-0: 0.75rem;
  --ao-font-size-1: 0.875rem;
  --ao-font-size-2: 1rem;
  --ao-font-size-3: 1.125rem;
  --ao-font-size-4: 1.25rem;
  --ao-font-size-5: 1.375rem;
  --ao-font-size-6: 1.5rem;
  --ao-font-size-7: 1.75rem;
  --ao-font-size-8: 2rem;
  --ao-font-size-9: 2.25rem;
  --ao-font-size-10: 2.5rem;
  --ao-font-size-11: 3rem;
  --ao-font-size-12: 3.5rem;
  --ao-font-size-13: 4rem;
  --ao-font-size-14: 4.5rem;
  --ao-font-size-15: 5rem;
}
