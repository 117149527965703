/* Typography styles */
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';

/* Display */
.ao-font-display1 {
  font-size: var(--ao-font-size-11);
  line-height: var(--ao-line-height-9);
  font-weight: var(--ao-font-weight-regular);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-13);
    line-height: var(--ao-line-height-12);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-15);
    line-height: var(--ao-line-height-14);
  }
}

.ao-font-display2 {
  font-size: var(--ao-font-size-10);
  line-height: var(--ao-line-height-8);
  font-weight: var(--ao-font-weight-regular);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-12);
    line-height: var(--ao-line-height-10);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-14);
    line-height: var(--ao-line-height-13);
  }
}

.ao-font-display3 {
  font-size: var(--ao-font-size-9);
  line-height: var(--ao-line-height-7);
  font-weight: var(--ao-font-weight-regular);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-11);
    line-height: var(--ao-line-height-9);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-13);
    line-height: var(--ao-line-height-12);
  }
}

.ao-font-display4 {
  font-size: var(--ao-font-size-8);
  line-height: var(--ao-line-height-6);
  font-weight: var(--ao-font-weight-regular);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-12);
    line-height: var(--ao-line-height-10);
  }
}

.ao-font-display5 {
  font-size: var(--ao-font-size-7);
  line-height: var(--ao-line-height-5);
  font-weight: var(--ao-font-weight-regular);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-9);
    line-height: var(--ao-line-height-7);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-11);
    line-height: var(--ao-line-height-9);
  }
}

.ao-font-display6 {
  font-size: var(--ao-font-size-6);
  line-height: var(--ao-line-height-4);
  font-weight: var(--ao-font-weight-regular);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }
}

/* Title */
.ao-font-title1 {
  font-size: var(--ao-font-size-7);
  line-height: var(--ao-line-height-5);
  font-weight: var(--ao-font-weight-bold);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }
}

.ao-font-title2 {
  font-size: var(--ao-font-size-6);
  line-height: var(--ao-line-height-4);
  font-weight: var(--ao-font-weight-bold);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-7);
    line-height: var(--ao-line-height-5);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }
}

.ao-font-title3 {
  font-size: var(--ao-font-size-5);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-bold);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-6);
    line-height: var(--ao-line-height-4);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-7);
    line-height: var(--ao-line-height-5);
  }
}

.ao-font-title4 {
  font-size: var(--ao-font-size-4);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-bold);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-4);
    line-height: var(--ao-line-height-3);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-6);
    line-height: var(--ao-line-height-4);
  }
}

.ao-font-title5 {
  font-size: var(--ao-font-size-3);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-bold);

  @media (min-width: 768px) {
    font-size: var(--ao-font-size-3);
    line-height: var(--ao-line-height-3);
  }

  @media (min-width: 1140px) {
    font-size: var(--ao-font-size-4);
    line-height: var(--ao-line-height-3);
  }
}

.ao-font-title6 {
  font-size: var(--ao-font-size-2);
  line-height: var(--ao-line-height-2);
  font-weight: var(--ao-font-weight-bold);
}

/* Body */
.ao-font-body1,
.ao-font-body1-bold {
  font-size: var(--ao-font-size-3);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-regular);
}

.ao-font-body2,
.ao-font-body2-bold {
  font-size: var(--ao-font-size-2);
  line-height: var(--ao-line-height-2);
  font-weight: var(--ao-font-weight-regular);
}

/* Small */
.ao-font-small,
.ao-font-small-bold {
  font-size: var(--ao-font-size-1);
  line-height: var(--ao-line-height-1);
  font-weight: var(--ao-font-weight-regular);
}

/* Tiny */
.ao-font-tiny,
.ao-font-tiny-bold {
  font-size: var(--ao-font-size-0);
  line-height: var(--ao-line-height-0);
  font-weight: var(--ao-font-weight-regular);
}

/* Bold variations */
.ao-font-body1-bold,
.ao-font-body2-bold,
.ao-font-small-bold,
.ao-font-tiny-bold {
  font-weight: var(--ao-font-weight-bold);
}

/* Text variations */
.ao-text--break-word {
  word-break: break-word;
}

/* Override L sizes */
.ao-font-max-medium {
  @media (min-width: 1140px) {
    .ao-font-display1 {
      font-size: var(--ao-font-size-13);
      line-height: var(--ao-line-height-12);
    }

    .ao-font-display2 {
      font-size: var(--ao-font-size-12);
      line-height: var(--ao-line-height-10);
    }

    .ao-font-display3 {
      font-size: var(--ao-font-size-11);
      line-height: var(--ao-line-height-9);
    }

    .ao-font-display4 {
      font-size: var(--ao-font-size-10);
      line-height: var(--ao-line-height-8);
    }

    .ao-font-display5 {
      font-size: var(--ao-font-size-9);
      line-height: var(--ao-line-height-7);
    }

    .ao-font-title1 {
      font-size: var(--ao-font-size-8);
      line-height: var(--ao-line-height-6);
    }

    .ao-font-title2 {
      font-size: var(--ao-font-size-7);
      line-height: var(--ao-line-height-5);
    }

    .ao-font-title3 {
      font-size: var(--ao-font-size-6);
      line-height: var(--ao-line-height-4);
    }

    .ao-font-title4 {
      font-size: var(--ao-font-size-4);
      line-height: var(--ao-line-height-3);
    }

    .ao-font-title5 {
      font-size: var(--ao-font-size-3);
      line-height: var(--ao-line-height-3);
    }
  }
}
