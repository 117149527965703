/* ------------------------------------*\
//    #TYPOGRAPHY
//\*------------------------------------ */

/**
 * Global font-family settings
 *
 * NB: THESE VARIABLES ARE THE FRAMEWORK DEFAULTS;
 *     ONLY MODIFY THESE IF YOU KNOW WHAT YOU ARE DOING
 *
 * 1. Default font-families, inspired (errm, ripped off) from:
 *    https://atlassian.design/guidelines/product/foundations/typography
 * 2. Our Brand specific (CVI) font sets, (currently used heavily in the Admin UI)
 * 3. Expose spacing varients as a map for convenience and mixin support
 * 4. Expose a `message` variant for use in the message composer in the Admin UI
 */

/* [1] */
$global-font-family--main: -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

// This should become main once we cleanup in AO-2460
$global-font-family--inter: 'Inter', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;
$global-font-family--mono: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', menlo, courier, monospace;

/* [2] */
$global-font-family--brand: 'Effra', 'Monserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$global-font-family--brand-mono: 'Roboto Mono', monospace;

/* [3] */
$font-family-data: (
  base: $global-font-family--main,
  inter: $global-font-family--inter,
  mono: $global-font-family--mono,
  brand: $global-font-family--brand,
  brand-mono: $global-font-family--brand-mono,
  message: $global-font-family--main /* [4] */,
);

// default (admin ui) font sizes
$font-sizes: (
  huge: 64px,
  h1: 48px,
  h2: 32px,
  h3: 24px,
  h4: 24px,
  h5: 20px,
  h6: 20px,
  p: 16px,
  medium: 16px,
  small: 14px,
  tiny: 12px,
);
$font-weights: (
  huge: 400,
  h1: 400,
  h2: 300,
  h3: 400,
  h4: 300,
  h5: 400,
  h6: 300,
  p: 400,
  medium: 300,
  small: 400,
  tiny: 400,
);
