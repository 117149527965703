@use 'sass:math';
@use 'sass:map';
@use 'sass:meta';
@use '../settings/settings.colors';
@use '../tools/tools.helpers' as helpers;

/* ========================================================================
//   #COLOR HELPERS
//   ======================================================================== 

// This is a (shameless) ripoff from
// https://github.com/Shopify/polaris/blob/master/src/styles/foundation/colors.scss
// The $color-palette-data is defined in setting.colors

// Returns the color value for a given color name and group.
//
// @param {String} $hue - The color's hue (named identifier)
// @param {String} $value - The darkness/lightness of the color. Defaults to base.
// @param {Color} $for-background - The background color on which this color will
// appear. Applies a multiply filter to ensure appropriate contrast.
// @return {Color} The color value.
//
// Example usage:
//   .my-class {
//     background-color: c.color(blue, dark);
//     color: c.color(ink, base, c.color(blue, dark));
//   }
//
//    lightest
//    lighter:
//    light:
//    base:
//    dark:
//    darker:
//    darkest:

// Darkens the foreground color by the background color.
// This is the same as the “multiply” filter in graphics apps.
// (NB: Helper function to c.color() - Not meant for stand-alone use)
//
// @param {Color} $foreground - The color to darken.
// @param {Color} $background - The background to base darkening on.
// @return {Color} The modified color. */

@function color-multiply($foreground, $background: null) {
  @if $background == null {
    $background: #fff;
  }

  @return math.div($foreground * $background, 255);
}

@function color($hue, $value: base, $for-background: null) {
  $fetched-color: map.get(map.get(settings.$color-palette-data, $hue), $value);

  @if map.has-key(settings.$color-palette-data, $fetched-color) {
    $fetched-color: map.get(map.get(settings.$color-palette-data, $fetched-color), $value);
  }

  @if $for-background != null {
    $fetched-color: color-multiply($fetched-color, $for-background);
  }

  @if meta.type-of($fetched-color) == color {
    @return $fetched-color;
  } @else {
    @error 'Color `#{$hue} - #{$value}` not found. Available colors: #{helpers.available-names(settings.$color-palette-data)}';
  }
}
