/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
  inspired/borrowed from https://github.com/inuitcss/inuitcss/blob/develop/objects/_objects.media.scss
 */

.o-media {
  display: flex;
  align-items: flex-start;

  &__figure {
    margin-right: var(--sp-smaller);
  }

  &__body {
    display: block;
    vertical-align: top;
    flex: 1 0 0px; // 0 0px is needed for IE
    min-width: 0; // ellipsis workaround
  }

  &__body,
  &__body :last-child {
    margin-bottom: 0;
  }

  &__title {
    margin: 0 0 var(--sp-smaller);
  }

  &__figure {
    display: block;
    vertical-align: top; // fallback dont remove
  }

  // Modifiers
  &--reverse > &__figure {
    order: 1;
    margin: 0 0 0 var(--sp-default);
  }

  &--center {
    align-items: center;

    > .o-media__figure,
    > .o-media__body {
      vertical-align: middle;
    }
  }

  &--center-grow {
    align-items: center;

    > .o-media__figure {
      vertical-align: top;
      align-self: flex-start;
    }

    > .o-media__body {
      vertical-align: middle;
    }
  }
}
