// override for material snackbar (SocialWallFeedComponent)
.ao-social-wall-snackbar {
  &.mdc-snackbar {
    .mdc-snackbar__surface {
      border-radius: 0;
      padding: 0;
      min-height: 52px;
      background-color: transparent;
      max-width: 600px;
    }
  }
}
