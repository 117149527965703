@use '../settings/settings.core' as core;
@use '../tools/tools.colors' as c;

.list {
  $block: &;

  > * {
    display: block;
    padding: var(--sp-default) 0;
  }
  > #{$block} {
    padding: 0 0 0 var(--sp-default);
  }

  &--hr {
    > * {
      border-bottom: 1px solid c.color(snow);

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &--top-hr {
    > * {
      border-top: 1px solid c.color(snow);

      &:first-child {
        border-top: 0;
      }
    }
  }

  &--padded {
    > * {
      padding: var(--sp-default) var(--sp-big);
    }
    > #{$block}--padded {
      padding-left: 0;
      margin-left: var(--sp-big);
    }

    &-message {
      > * {
        padding: core.$message-module-vertical-spacing core.$message-module-horizontal-spacing;
      }
      > #{$block}--padded {
        padding-left: 0;
        margin-left: core.$message-module-horizontal-spacing;
      }
    }
  }

  &--elevated {
    @extend #{$block}--top-hr;
    @extend #{$block}--padded;

    border-radius: 5px;
    box-shadow: 0 0 25px rgb(0 0 0 / 20%);

    + * {
      border-top: 0;
    }
  }
}
