@use 'tools/tools.colors' as c;
@use 'tools/tools.typography' as typog;
@use 'viewer-ui';
@use '@ctrl/ngx-emoji-mart/picker';
@use './device-safearea-overrides';

body,
html {
  display: block;
  height: 100%;
  width: 100%;
}

// avoid safari zoom bug
select {
  font-size: 16px;
}

// sidebar safearea color
:root {
  --native-sidebar-safearea-bg-color: #fff;
  --mat-sidenav-container-background-color: var(--native-sidebar-safearea-bg-color);
}

.ao-cometchat-responsive {
  width: 100%;

  &::ng-deep {
    * {
      font-family: typog.font-family(inter);
    }
  }
}

// emoji library for chat search bar goes offlimit
[id^='emoji-mart-search'] {
  width: 89% !important;
}

.cdk-virtual-scroll-content-wrapper {
  width: 100% !important;
  max-width: 100%;
}

.linkify {
  color: c.color(blue, base) !important;
}

.ao-message-page__bottom {
  height: 1px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch !important; /* Important for iOS */
}

// Fixes a situation where a modal is open with reaction picker  on top. As both are overlays they bug each other
.reaction-picker-opened .cdk-overlay-connected-position-bounding-box {
  display: contents;
}
