@charset "UTF-8";
/* Typography styles */
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap';
/* Display */
.ao-font-display1 {
  font-size: var(--ao-font-size-11);
  line-height: var(--ao-line-height-9);
  font-weight: var(--ao-font-weight-regular);
}
@media (min-width: 768px) {
  .ao-font-display1 {
    font-size: var(--ao-font-size-13);
    line-height: var(--ao-line-height-12);
  }
}
@media (min-width: 1140px) {
  .ao-font-display1 {
    font-size: var(--ao-font-size-15);
    line-height: var(--ao-line-height-14);
  }
}

.ao-font-display2 {
  font-size: var(--ao-font-size-10);
  line-height: var(--ao-line-height-8);
  font-weight: var(--ao-font-weight-regular);
}
@media (min-width: 768px) {
  .ao-font-display2 {
    font-size: var(--ao-font-size-12);
    line-height: var(--ao-line-height-10);
  }
}
@media (min-width: 1140px) {
  .ao-font-display2 {
    font-size: var(--ao-font-size-14);
    line-height: var(--ao-line-height-13);
  }
}

.ao-font-display3 {
  font-size: var(--ao-font-size-9);
  line-height: var(--ao-line-height-7);
  font-weight: var(--ao-font-weight-regular);
}
@media (min-width: 768px) {
  .ao-font-display3 {
    font-size: var(--ao-font-size-11);
    line-height: var(--ao-line-height-9);
  }
}
@media (min-width: 1140px) {
  .ao-font-display3 {
    font-size: var(--ao-font-size-13);
    line-height: var(--ao-line-height-12);
  }
}

.ao-font-display4 {
  font-size: var(--ao-font-size-8);
  line-height: var(--ao-line-height-6);
  font-weight: var(--ao-font-weight-regular);
}
@media (min-width: 768px) {
  .ao-font-display4 {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }
}
@media (min-width: 1140px) {
  .ao-font-display4 {
    font-size: var(--ao-font-size-12);
    line-height: var(--ao-line-height-10);
  }
}

.ao-font-display5 {
  font-size: var(--ao-font-size-7);
  line-height: var(--ao-line-height-5);
  font-weight: var(--ao-font-weight-regular);
}
@media (min-width: 768px) {
  .ao-font-display5 {
    font-size: var(--ao-font-size-9);
    line-height: var(--ao-line-height-7);
  }
}
@media (min-width: 1140px) {
  .ao-font-display5 {
    font-size: var(--ao-font-size-11);
    line-height: var(--ao-line-height-9);
  }
}

.ao-font-display6 {
  font-size: var(--ao-font-size-6);
  line-height: var(--ao-line-height-4);
  font-weight: var(--ao-font-weight-regular);
}
@media (min-width: 768px) {
  .ao-font-display6 {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }
}
@media (min-width: 1140px) {
  .ao-font-display6 {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }
}

/* Title */
.ao-font-title1 {
  font-size: var(--ao-font-size-7);
  line-height: var(--ao-line-height-5);
  font-weight: var(--ao-font-weight-bold);
}
@media (min-width: 768px) {
  .ao-font-title1 {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }
}
@media (min-width: 1140px) {
  .ao-font-title1 {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }
}

.ao-font-title2 {
  font-size: var(--ao-font-size-6);
  line-height: var(--ao-line-height-4);
  font-weight: var(--ao-font-weight-bold);
}
@media (min-width: 768px) {
  .ao-font-title2 {
    font-size: var(--ao-font-size-7);
    line-height: var(--ao-line-height-5);
  }
}
@media (min-width: 1140px) {
  .ao-font-title2 {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }
}

.ao-font-title3 {
  font-size: var(--ao-font-size-5);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-bold);
}
@media (min-width: 768px) {
  .ao-font-title3 {
    font-size: var(--ao-font-size-6);
    line-height: var(--ao-line-height-4);
  }
}
@media (min-width: 1140px) {
  .ao-font-title3 {
    font-size: var(--ao-font-size-7);
    line-height: var(--ao-line-height-5);
  }
}

.ao-font-title4 {
  font-size: var(--ao-font-size-4);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-bold);
}
@media (min-width: 768px) {
  .ao-font-title4 {
    font-size: var(--ao-font-size-4);
    line-height: var(--ao-line-height-3);
  }
}
@media (min-width: 1140px) {
  .ao-font-title4 {
    font-size: var(--ao-font-size-6);
    line-height: var(--ao-line-height-4);
  }
}

.ao-font-title5 {
  font-size: var(--ao-font-size-3);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-bold);
}
@media (min-width: 768px) {
  .ao-font-title5 {
    font-size: var(--ao-font-size-3);
    line-height: var(--ao-line-height-3);
  }
}
@media (min-width: 1140px) {
  .ao-font-title5 {
    font-size: var(--ao-font-size-4);
    line-height: var(--ao-line-height-3);
  }
}

.ao-font-title6 {
  font-size: var(--ao-font-size-2);
  line-height: var(--ao-line-height-2);
  font-weight: var(--ao-font-weight-bold);
}

/* Body */
.ao-font-body1,
.ao-font-body1-bold {
  font-size: var(--ao-font-size-3);
  line-height: var(--ao-line-height-3);
  font-weight: var(--ao-font-weight-regular);
}

.ao-font-body2,
.ao-font-body2-bold {
  font-size: var(--ao-font-size-2);
  line-height: var(--ao-line-height-2);
  font-weight: var(--ao-font-weight-regular);
}

/* Small */
.ao-font-small,
.ao-font-small-bold {
  font-size: var(--ao-font-size-1);
  line-height: var(--ao-line-height-1);
  font-weight: var(--ao-font-weight-regular);
}

/* Tiny */
.ao-font-tiny,
.ao-font-tiny-bold {
  font-size: var(--ao-font-size-0);
  line-height: var(--ao-line-height-0);
  font-weight: var(--ao-font-weight-regular);
}

/* Bold variations */
.ao-font-body1-bold,
.ao-font-body2-bold,
.ao-font-small-bold,
.ao-font-tiny-bold {
  font-weight: var(--ao-font-weight-bold);
}

/* Text variations */
.ao-text--break-word {
  word-break: break-word;
}

/* Override L sizes */
@media (min-width: 1140px) {
  .ao-font-max-medium .ao-font-display1 {
    font-size: var(--ao-font-size-13);
    line-height: var(--ao-line-height-12);
  }
  .ao-font-max-medium .ao-font-display2 {
    font-size: var(--ao-font-size-12);
    line-height: var(--ao-line-height-10);
  }
  .ao-font-max-medium .ao-font-display3 {
    font-size: var(--ao-font-size-11);
    line-height: var(--ao-line-height-9);
  }
  .ao-font-max-medium .ao-font-display4 {
    font-size: var(--ao-font-size-10);
    line-height: var(--ao-line-height-8);
  }
  .ao-font-max-medium .ao-font-display5 {
    font-size: var(--ao-font-size-9);
    line-height: var(--ao-line-height-7);
  }
  .ao-font-max-medium .ao-font-title1 {
    font-size: var(--ao-font-size-8);
    line-height: var(--ao-line-height-6);
  }
  .ao-font-max-medium .ao-font-title2 {
    font-size: var(--ao-font-size-7);
    line-height: var(--ao-line-height-5);
  }
  .ao-font-max-medium .ao-font-title3 {
    font-size: var(--ao-font-size-6);
    line-height: var(--ao-line-height-4);
  }
  .ao-font-max-medium .ao-font-title4 {
    font-size: var(--ao-font-size-4);
    line-height: var(--ao-line-height-3);
  }
  .ao-font-max-medium .ao-font-title5 {
    font-size: var(--ao-font-size-3);
    line-height: var(--ao-line-height-3);
  }
}

/* ------------------------------------*\
//    #COLORS
//\*------------------------------------ */
/* ========================================================================
//   #HELPER MIXINS & FUNCTIONS
//   ========================================================================

/// Returns the list of available names in a given map.
/// @param {Map} $map - The map of data to list the names from.
/// @param {Number} $map - The level of depth to get names from.
/// @return {String} The list of names in the map. */
/* ========================================================================
//   #COLOR HELPERS
//   ======================================================================== 

// This is a (shameless) ripoff from
// https://github.com/Shopify/polaris/blob/master/src/styles/foundation/colors.scss
// The $color-palette-data is defined in setting.colors

// Returns the color value for a given color name and group.
//
// @param {String} $hue - The color's hue (named identifier)
// @param {String} $value - The darkness/lightness of the color. Defaults to base.
// @param {Color} $for-background - The background color on which this color will
// appear. Applies a multiply filter to ensure appropriate contrast.
// @return {Color} The color value.
//
// Example usage:
//   .my-class {
//     background-color: c.color(blue, dark);
//     color: c.color(ink, base, c.color(blue, dark));
//   }
//
//    lightest
//    lighter:
//    light:
//    base:
//    dark:
//    darker:
//    darkest:

// Darkens the foreground color by the background color.
// This is the same as the “multiply” filter in graphics apps.
// (NB: Helper function to c.color() - Not meant for stand-alone use)
//
// @param {Color} $foreground - The color to darken.
// @param {Color} $background - The background to base darkening on.
// @return {Color} The modified color. */
.ao-color-ink--lighter {
  color: #79839a;
}

.ao-color-blue--base {
  color: #4680fe;
}

/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */
:root {
  --ao-font-size-0: 0.75rem;
  --ao-font-size-1: 0.875rem;
  --ao-font-size-2: 1rem;
  --ao-font-size-3: 1.125rem;
  --ao-font-size-4: 1.25rem;
  --ao-font-size-5: 1.375rem;
  --ao-font-size-6: 1.5rem;
  --ao-font-size-7: 1.75rem;
  --ao-font-size-8: 2rem;
  --ao-font-size-9: 2.25rem;
  --ao-font-size-10: 2.5rem;
  --ao-font-size-11: 3rem;
  --ao-font-size-12: 3.5rem;
  --ao-font-size-13: 4rem;
  --ao-font-size-14: 4.5rem;
  --ao-font-size-15: 5rem;
}

/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */
:root {
  --ao-font-weight-regular: 400;
  --ao-font-weight-bold: 700;
}

/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */
:root {
  --ao-line-height-0: 16px;
  --ao-line-height-1: 20px;
  --ao-line-height-2: 24px;
  --ao-line-height-3: 28px;
  --ao-line-height-4: 32px;
  --ao-line-height-5: 36px;
  --ao-line-height-6: 40px;
  --ao-line-height-7: 44px;
  --ao-line-height-8: 48px;
  --ao-line-height-9: 56px;
  --ao-line-height-10: 64px;
  --ao-line-height-11: 72px;
  --ao-line-height-12: 80px;
  --ao-line-height-13: 88px;
  --ao-line-height-14: 96px;
}

/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */
:root {
  --sp-none: 0px;
  --sp-tinier: 2px;
  --sp-tiny: 4px;
  --sp-smaller: 8px;
  --sp-small: 12px;
  --sp-default: 16px;
  --sp-big: 24px;
  --sp-bigger: 32px;
  --sp-great: 40px;
  --sp-greater: 48px;
  --sp-huge: 64px;
  --sp-huger: 80px;
  --sp-immense: 96px;
  --sp-gigantic: 128px;
  --sp-enormous: 160px;
}