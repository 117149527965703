/* ------------------------------------*\
//    #DEVICES
//\*------------------------------------ */

// Mobile
$mobile-portrait-width: 394px;
$mobile-portrait-height: 818px;
$mobile-landscape-width: 806px;
$mobile-landscape-height: 395px;
$mobile-content-width: 1080px;
$mobile-max-width: 719px;
$mobile-head-width: 422px;

// Tablet
$tablet-portrait-width: 870px;
$tablet-portrait-height: 1240px;
$tablet-landscape-width: 1229px;
$tablet-landscape-height: 871px;

// Desktop
$desktop-width: 1700px;
$desktop-height: 1000px;

// Main sidebar
$main-sidebar-width: 73px;

// Message
$message-padding-width: 25px;
$message-outer-padding-width: $main-sidebar-width + $message-padding-width * 2 - 30px;
$message-sidebar-width: 290px;
$message-sidebar-padding-width: 10px;
$message-sidebar-outer-width: $message-sidebar-width + $message-sidebar-padding-width * 2;
$message-outer-width: $message-outer-padding-width + $message-sidebar-outer-width;
