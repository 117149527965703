/* ------------------------------------*\
//    #CORE
//\*------------------------------------ */

// Base typographical sizing (baseline grid default)

$global-font-size: 16px !default;
$global-line-height: 1.5 !default;

// Default message module spacing variants

$message-module-vertical-spacing: 15px;

// default
$message-module-horizontal-spacing: 60px;
$message-module-horizontal-mobile-spacing: 20px;

// if bootstrap grid is used
$message-module-grid-horizontal-spacing: 45px;
$message-module-grid-horizontal-mobile-spacing: 5px;

// Expose border-radius varients as a map for convenience and mixin support
$radius-data: (
  default: 4px,
  double: 8px,
  triple: 12px,
  big: 9999px,
);
