/**
 * Do not edit directly
 * Generated on Thu, 10 Feb 2022 16:42:35 GMT
 */

:root {
  --ao-line-height-0: 16px;
  --ao-line-height-1: 20px;
  --ao-line-height-2: 24px;
  --ao-line-height-3: 28px;
  --ao-line-height-4: 32px;
  --ao-line-height-5: 36px;
  --ao-line-height-6: 40px;
  --ao-line-height-7: 44px;
  --ao-line-height-8: 48px;
  --ao-line-height-9: 56px;
  --ao-line-height-10: 64px;
  --ao-line-height-11: 72px;
  --ao-line-height-12: 80px;
  --ao-line-height-13: 88px;
  --ao-line-height-14: 96px;
}
