/* ========================================================================
//   #ANIMATION HELPERS
//   ======================================================================== */

@keyframes point {
  0% {
    top: 0;
  }

  10% {
    top: 10px;
  }

  20% {
    top: 0;
  }

  30% {
    top: 10px;
  }

  40% {
    top: 0;
  }

  100% {
    top: 0;
  }
}

@keyframes darkThemeSkeletonAnimation {
  0% {
    background: rgba(255, 255, 255, 12%);
  }

  50% {
    background: rgba(255, 255, 255, 5%);
  }

  100% {
    background: rgba(255, 255, 255, 12%);
  }
}

@keyframes lightThemeSkeletonAnimation {
  0% {
    background: rgba(0, 0, 0, 8%);
  }

  50% {
    background: rgba(0, 0, 0, 3%);
  }

  100% {
    background: rgba(0, 0, 0, 8%);
  }
}

@keyframes reverseSkeletonAnimation {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@mixin addSkeletonAnimation() {
  animation-name: reverseSkeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin addDarkThemeSkeletonAnimation() {
  animation-name: darkThemeSkeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin addLightThemeSkeletonAnimation() {
  animation-name: lightThemeSkeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes keyframes-fromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes keyframes-fromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes keyframes-fromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes keyframes-fromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes keyframes-popDown {
  0% {
    opacity: 0;
    transform: translateY(5%) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
